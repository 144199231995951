import { defineStore } from 'pinia'
import { Jobsite } from '@quotetome/materials-api'

interface State {
  projectToCreate: Jobsite | null,
}

export default defineStore('accounting', {
  state: (): State => ({
    projectToCreate: null,
  }),
  actions: {
    setProjectToCreate(project: Jobsite) {
      this.projectToCreate = project
    }
  }
})
